<template>
  <v-row>
    <v-col cols="12" class="d-flex align-center">
      <h2>{{ $t('lotto_game.head') }}</h2>
      <v-divider class="mx-3" vertical />
      <v-breadcrumbs :items="breadcrumbsItems" divider=">>" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title> {{ $t('lotto_game.create_head') }}</v-card-title>

        <v-form ref="form" v-model="formValid" @submit.prevent="submitForm">
          <v-divider class="mx-3" />
          <!-- Title Input -->
          <v-card-actions>
            <v-row class="d-flex flex-column">
              <v-col cols="12" xs="6" md="6" xl="6">
                <v-text-field v-model="formData.title" :label="$t('lotto_game.detail_input1')" type="text" outlined dense
                  disabled></v-text-field>
              </v-col>

              <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                <v-text-field v-model="formData.round" :label="$t('lotto_game.detail_input2')" type="date" :min="minDate"
                  outlined dense disabled></v-text-field>
              </v-col>

              <v-row class="ma-0">
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeStart" :label="$t('lotto_game.detail_input3')" type="date"
                    :min="minDate" outlined dense disabled></v-text-field></v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formData.timeEnd" :label="$t('lotto_game.detail_input4')" type="date"
                    :min="minDate" outlined dense disabled></v-text-field></v-col>
              </v-row>
            </v-row>
          </v-card-actions>

          <!-- Reward type and amount Input -->
          <v-col>
            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('lotto_game.reward3_head') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formResultData.resultThreeDigitsOne" :rules="formRule.top3"
                    :label="$t('lotto_game.reward1')" :placeholder="$t('lotto_game.alert6')" maxLength="3" outlined dense
                    :disabled="disabledResult"></v-text-field>
                </v-col>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formResultData.resultThreeDigitsTwo" :rules="formRule.top3"
                    :label="$t('lotto_game.reward2')" :placeholder="$t('lotto_game.alert6')" maxLength="3" outlined dense
                    :disabled="disabledResult"></v-text-field>
                </v-col>
              </v-row>
            </v-col>

            <v-col>
              <v-row>
                <v-card-title class="pt-0 pb-2 px-3">{{ $t('lotto_game.reward2_head') }}</v-card-title>
              </v-row>
              <v-row>
                <v-col cols="6" xs="6" md="3" xl="3" class="py-0">
                  <v-text-field v-model="formResultData.resultTwoDigits" :rules="formRule.bottom2"
                    :label="$t('lotto_game.reward')" :placeholder="$t('lotto_game.alert7')" maxLength="2" outlined dense
                    :disabled="disabledResult"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-col>

          <v-card-actions>
            <v-spacer />
            <v-btn color="warning" @click="$router.push({ name: 'predict-lotto' })">
              <v-icon>mdi-arrow-left</v-icon>
              <span class="px-3"> {{ $t('back') }}</span>
            </v-btn>
            <v-btn color="success" class="px-3" :disabled="disabledResult" @click="submitForm" :loading="loading">
              <v-icon small>mdi-square-edit-outline</v-icon>
              <span color="primary" class="px-3">{{ $t('lotto_game.reward_send') }}</span></v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'
import StaffLimitAccessPage from '@/components/staff/StaffLimitAccessPage.vue'
import store from '@/store'
import moment from 'moment'

export default {
  components: {
    StaffLimitAccessPage,
  },
  data() {
    return {
      loading: false,
      formValid: true,
      formRule: {
        top3: [
          value => (value !== undefined && value !== null) || this.$t('lotto_game.alert8'),
          value => /^[0-9]+$/.test(value) || this.$t('lotto_game.alert8'),
          value => value.length === 3 || this.$t('lotto_game.alert9'),
        ],
        bottom2: [
          value => (value !== undefined && value !== null) || this.$t('lotto_game.alert8'),
          value => /^[0-9]+$/.test(value) || this.$t('lotto_game.alert8'),
          value => value.length === 2 || this.$t('lotto_game.alert10'),
        ],
      },
      formData: {
        title: '',
        round: '',
        timeStart: '',
        timeEnd: '',
        typeBetUse: 'coin',
        betUse: 1,
        typeReward: 'money',
        rewardLastTwoDigits: null,
        multiplyTurnOverTwoDigits: 0,
        rewardLastThreeDigits: null,
        multiplyTurnOverThreeDigits: 0,
      },
      formResultData: {
        resultThreeDigitsTwo: '',
        resultThreeDigitsOne: '',
        resultTwoDigits: '',
      },
      minDate: moment().format('YYYY-MM-DD'),
      rewardTypes: ['point', 'coin', 'money'],
      disabledResult: true,

      breadcrumbsItems: [
        {
          text: this.$t('lotto_game.head'),
          disabled: false,
          href: '/predictLotto',
        },
        {
          text: this.$t('lotto_game.header5_2'),
          disabled: true,
          href: '',
        },
      ],

      acceptItems: [
        {
          text: this.$t('yes'),
          value: 'yes',
        },
        {
          text: this.$t('not'),
          value: 'no',
        },
      ],
      roles: [],
    }
  },
  async created() {
    this.addLogPage()
    try {
      this.roles = await this.$store.dispatch('getRoleData')
    } catch (e) { }

    this.getLottoData()
  },
  methods: {
    async addLogPage() {
      try {
        let data
        const userSystem = store.getters.getuserInfo
        await axios.get('https://api.ipify.org?format=json').then(res =>
          (data = {
            ip: res.data.ip,
            name: 'ทายหวย',
            url: window.location.href,
            detail: 'ส่งผลรางวัล ทายหวย',
            admin: userSystem.name ? userSystem.name : '' ? userSystem.name : '',
          }),
        )
        await this.$store.dispatch('addLogPage', data)
      } catch (e) {
        // if (!e.message.includes('Unexpected token') || !e?.includes('Unexpected token') || !e.message.includes('Unexpected identifier')) {
        //   this.$swal.fire({
        //     icon: 'error',
        //     title: e.message,
        //   })
        // }
      }
    },
    submitForm() {
      if (this.formResultData.resultThreeDigitsOne === this.formResultData.resultThreeDigitsTwo) {
        this.$swal.fire({
          icon: 'warning',
          title: this.$t('alert'),
          text: this.$t('lotto_game.alert11'),
          showCancelButton: false,
          confirmButtonText: this.$t('confirm'),
        })
      } else {
        if (this.$refs.form.validate()) {
          this.$swal
            .fire({
              icon: 'warning',
              title: this.$t('alert'),
              text: `${this.$t('lotto_game.alert12')}${this.formData.title}`,
              showCancelButton: true,
              confirmButtonText: this.$t('confirm'),
              cancelButtonText: this.$t('cancel'),
            })
            .then(async action => {
              if (action.isConfirmed) {
                await this.updateResultLotto()
              }
            })
        }
      }
    },

    async getLottoData() {
      const id = this.$route.params.id
      this.loading = true
      try {
        let res = await this.$store.dispatch('getLottoList', `id=${id}`)
        let resData = res.data[0]

        function isEmptyValues(...values) {
          for (const value of values) {
            if (value === null || value === '') {
              return true
            }
          }
          return false
        }

        // // check can send result from time end and result values
        const currentTime = moment().format('YYYY-MM-DD HH:mm')
        const timeEnd = moment(resData.timeEnd).format('YYYY-MM-DD HH:mm')

        if (
          moment(currentTime).isAfter(timeEnd) &&
          isEmptyValues(resData.resultTwoDigits) &&
          isEmptyValues(resData.resultThreeDigitsOne) &&
          isEmptyValues(resData.resultThreeDigitsTwo)
        ) {
          this.disabledResult = false
        }

        this.formData = {
          title: resData.title,
          round: moment(resData.round).format('YYYY-MM-DD') || null,
          timeStart: moment(resData.timeStart).format('YYYY-MM-DD') || null,
          timeEnd: moment(resData.timeEnd).format('YYYY-MM-DD') || null,
          typeBetUse: resData.typeBetUse,
          betUse: resData.betUse,
          typeReward: resData.typeReward,
          rewardLastTwoDigits: resData.rewardLastTwoDigits,
          multiplyTurnOverTwoDigits: resData.multiplyTurnOverTwoDigits,
          rewardLastThreeDigits: resData.rewardLastThreeDigits,
          multiplyTurnOverThreeDigits: resData.multiplyTurnOverThreeDigits,
        }
        this.formResultData = {
          resultThreeDigitsTwo: resData.resultThreeDigitsTwo || '',
          resultThreeDigitsOne: resData.resultThreeDigitsOne || '',
          resultTwoDigits: resData.resultTwoDigits || '',
        }

        this.loading = false
      } catch (e) { }
    },

    async updateResultLotto() {
      const id = this.$route.params.id
      const data = { ...this.formResultData, id: id }
      this.loading = true
      try {
        let res = await this.$store.dispatch('updateResultLotto', data)
        if (res.status === 'success') {
          this.$swal.fire(thi.t('lotto_game.send_complete'), '', 'success').then(action => {
            this.$router.push({ name: 'predict-lotto-result', params: id })
          })
        } else if (res.code === 400) {
          this.$swal.fire(`${this.$t('lotto_game.send_fail2_1')} ${this.formData.title} ${this.$t('lotto_game.send_fail2_2')}`, '', 'error')
        } else {
          this.$swal.fire(this.$t('lotto_game.send_fail3'), '', 'error')
        }
        this.loading = false
      } catch (e) { }
    },
  },
}
</script>
